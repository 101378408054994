import React from 'react';

function HomeIcon() {
    return (
        <svg className='saidbar-icon' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.4548 12.155L22.7881 4.20002C21.9678 3.6355 20.9955 3.33325 19.9998 3.33325C19.004 3.33325 18.0317 3.6355 17.2115 4.20002L5.54479 12.155C4.86068 12.6239 4.30155 13.2529 3.91608 13.9872C3.53061 14.7216 3.33048 15.539 3.33312 16.3684V31.59C3.32424 32.9259 3.84573 34.2107 4.78314 35.1625C5.72054 36.1143 6.99727 36.6552 8.33312 36.6667H31.6664C33.0023 36.6552 34.279 36.1143 35.2164 35.1625C36.1538 34.2107 36.6753 32.9259 36.6664 31.59V16.3684C36.6691 15.539 36.469 14.7216 36.0835 13.9872C35.698 13.2529 35.1389 12.6239 34.4548 12.155ZM16.6665 33.3333V25H23.3331V33.3333H16.6665ZM33.3331 31.59C33.3425 32.042 33.1724 32.4793 32.86 32.806C32.5476 33.1328 32.1184 33.3224 31.6664 33.3333H26.6665V23.3334C26.6665 22.8913 26.4909 22.4674 26.1783 22.1548C25.8657 21.8423 25.4418 21.6667 24.9998 21.6667H14.9998C14.5578 21.6667 14.1338 21.8423 13.8213 22.1548C13.5087 22.4674 13.3331 22.8913 13.3331 23.3334V33.3333H8.33312C7.88118 33.3224 7.45199 33.1328 7.1396 32.806C6.8272 32.4793 6.65706 32.042 6.66645 31.59V16.3684C6.66418 16.0818 6.73192 15.799 6.86379 15.5445C6.99566 15.2901 7.18766 15.0717 7.42312 14.9084L19.0898 6.95335C19.3574 6.76887 19.6748 6.67009 19.9998 6.67009C20.3248 6.67009 20.6422 6.76887 20.9098 6.95335L32.5764 14.9084C32.8119 15.0717 33.0039 15.2901 33.1358 15.5445C33.2676 15.799 33.3354 16.0818 33.3331 16.3684V31.59Z" />
        </svg>

    );
}

export default HomeIcon;
