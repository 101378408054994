import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    contactData: any;
}


export const isContactGet = createAsyncThunk('contact', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/contact');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    contactData: [
       
    ]
}
export const ContactReducer = createSlice({
    name: 'contact',
    initialState,
    reducers: {

        isDeleteContact: (state, { payload }) => {
            state.contactData.data = state.contactData.data.filter(({ id }: { id: number }) => id !== payload)
        },

        isAddAllContact: (state, { payload }) => {
            state.contactData = payload
        },
        isEditContact: (state, { payload }) => {
            state.contactData.data = state.contactData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isContactGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isContactGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.contactData = action.payload;
            state.isSuccess = true;
        },
        [isContactGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const {isEditContact, isDeleteContact,isAddAllContact } = ContactReducer.actions

export default ContactReducer.reducer