import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    commentData: any;
}


export const isCommentGet = createAsyncThunk('comment', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/comment');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    commentData: [
       
    ]
}
export const CommentReducer = createSlice({
    name: 'comment',
    initialState,
    reducers: {

        isDeleteComment: (state, { payload }) => {
            state.commentData.data = state.commentData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isAddComment: (state, { payload }) => {
            state.commentData.data = [...state.commentData.data, payload]
        },
        isAddAllComment: (state, { payload }) => {
            state.commentData = payload
        },
        isEditComment: (state, { payload }) => {
            state.commentData.data = state.commentData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isCommentGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isCommentGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.commentData = action.payload;
            state.isSuccess = true;
        },
        [isCommentGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const {isEditComment, isDeleteComment,isAddAllComment,isAddComment } = CommentReducer.actions

export default CommentReducer.reducer