import ContentLoader from 'react-content-loader'
const FormLoader = () => {
    return (
        <ContentLoader
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="8" y="6" rx="4" ry="4" width="33%" height="38" />
            <rect x="34%" y="6" rx="4" ry="4" width="33%" height="38" />
            <rect x="67.5%" y="6" rx="4" ry="4" width="33%" height="38" />
        </ContentLoader>
    )

}
export default FormLoader