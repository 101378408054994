import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Routes, Route, Link, NavLink, useNavigate } from "react-router-dom";
import Home from '../Home';
import Profile from '../Profile';
import HomeIcon from '../../ui/icon/HomeIcon';
import users from '../../ui/icon/users.svg'
import Login from '../Login';
import PasswordChangePage from '../PasswordChangePage';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingFunction, isLogoutFunction, menuBtn } from '../../redux-toolkit/reducer/ProfileReducer';
import { AppDispatch } from '../../redux-toolkit/store';
import { Config } from '../../redux-toolkit/config/Config';
import Contact from '../../components/contact/Contact';
import Swal from 'sweetalert2';
import axios from 'axios';
import Show from '../../components/show/Show';
import logo from '../../ui/nambiotestlogo.svg'
import LeftIcon from '../../ui/icon/LeftIcon';
import Menu from '../../ui/icon/Menu';
import NotFound from '../NotFound';
import CommentAdd from '../../components/comment/CommentAdd';
import Comment from '../../components/comment/Comment';
import CategoryAdd from '../../components/category/CategoryAdd';
import Category from '../../components/category/Category';
import Product from '../../components/product/Product';
import ProductAdd from '../../components/product/ProductAdd';
import Sertifkat from '../../components/sertifkat/Statistics';
import SertifkatAdd from '../../components/sertifkat/StatisticsAdd';
import Advantage from '../../components/advantage/Advantage';
import AdvantageAdd from '../../components/advantage/AdvantageAdd';
import Statistics from '../../components/statistics/Statistics';
import StatisticsAdd from '../../components/statistics/StatisticsAdd';
import About from '../../components/about/About';
import AboutAdd from '../../components/about/AboutAdd';
import Header from '../../components/header/Header';
import HeaderCard from '../../components/headercard/HeaderCard';
import HeaderCardAdd from '../../components/headercard/headerCardAdd';
import HeaderAdd from '../../components/header/HeaderAdd';
import PriceList from '../../components/price-list/PriceList';
import PriceListAdd from '../../components/price-list/PriceListAdd';

function RouteLayout() {
    const [con] = useState(false);

    const dispatch = useDispatch<AppDispatch>()
    const { menu, profile, token } = useSelector((state: any) => state.ProfileReducer)

    const path = useNavigate()
    const logoutPost = async () => {
        dispatch(isLoadingFunction(true))
        try {
            let res = await axios.post('/logout', { refresh: localStorage.getItem('refresh') })
            if (res.status === 200) {
                dispatch(isLogoutFunction())
                path('/')
                Swal.fire('Tizmida chiqildi!', '', 'success')
            }
        }
        catch (err) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Hatolik",
                showConfirmButton: false,
                timer: 1500
            })
        }
        finally {
            dispatch(isLoadingFunction(false))
        }

    }
    const logout = async () => {
        Swal.fire({
            title: 'Tizimdan chiqiazimi?',
            showDenyButton: true,
            confirmButtonText: 'ha',
            denyButtonText: `Yo'q`,

        }).then((result) => {
            if (result.isConfirmed) {

                logoutPost()

            }
        })

    }

    return (
        <>
            {
                token?.length > 1 ?
                    <div className='layout' style={con ? { display: 'none' } : {}}>
                        <div className={`saidbar ${menu ? 'active' : ''}`}>
                            <div className="saidbar-logo">
                                <NavLink to='/' >
                                    <LazyLoadImage
                                        src={logo}
                                        effect="blur"
                                    />
                                </NavLink>
                            </div>
                            <button className="cancel btn title flex center-align text-black" onClick={() => {
                                dispatch(menuBtn(false))
                            }}>
                                <LeftIcon />
                                <span>   Ortga</span>
                            </button>
                            <ul className="saidabr-item">
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Asosiy </span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/header'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Bosh sahifa </span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/header-card'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>B.S Card </span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/comment'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Mijozlar fikri </span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/category'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Kategoriya</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/product'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Maxsulotlar</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/advantage'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Afzallik</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/statistics'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Statistika</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/sertifkat'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Sertifkat</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/about'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Biz haqimizda</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/price-list'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Narxlar</span>
                                    </NavLink>
                                </li>
                                <li className="saidbar-link">
                                    <NavLink onClick={() => {
                                        dispatch(menuBtn(false))
                                    }} className={({ isActive }) => isActive ? "active" : ''
                                    } to='/contact'>
                                        <span className='w60 bg-green flex center-content center-align radius-50'><HomeIcon /></span>
                                        <span>Izohlar</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="contant">
                            <div className={`media-bg ${menu ? 'active' : ''}`} onClick={() => {
                                dispatch(menuBtn(false))
                            }}>
                            </div>
                            <div className="contant-detil flex between center-align">
                                <div className="logo-media">
                                    <NavLink to='/' >
                                        <LazyLoadImage
                                            src={logo}
                                            effect="blur"
                                        />
                                    </NavLink>
                                    <div>
                                        <button className={`hamburger_btn ${menu ? 'active' : ''}`} onClick={() => {
                                            dispatch(menuBtn(true))
                                        }}>
                                            <Menu />
                                        </button>
                                    </div>
                                </div>
                                <div className="contant-user flex center-align">
                                    {/* <div className="dark-mode border relative ">
                                        <input type="checkbox" id="darkmode" className='absolute t-0 l-0 w100 h100 opacity' onChange={darkFun} />
                                        <label htmlFor="darkmode" className='dark-mode-item bg-green block radius-50 relative'></label>
                                    </div> */}

                                    <div >
                                        <button onClick={logout}>
                                            <svg width={30} fill='#8e8ea1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" /></svg>
                                        </button>
                                    </div>
                                    <Link to='/profile'>
                                        <div className="contant-avatar flex center-align">
                                            <LazyLoadImage
                                                className='w60 cover'
                                                src={users}
                                                effect="blur"
                                            />
                                            <h1>{profile?.name}</h1>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ''
            }
            <Config />
            <Routes>
                {/* <Route path="*" element={<Page404 />} /> */}
                {
                    token?.length > 0 ?
                        <>
                            <Route path="/show/:url/:id" element={<Show />} />
                            <Route path="/" element={<Home />} />
                            {/* header */}
                            <Route path="/header" element={<Header />} />
                            <Route path="/header/add" element={<HeaderAdd />} />
                            <Route path="/header/edit/:id" element={<HeaderAdd />} />
                            {/* header-card */}
                            <Route path="/header-card" element={<HeaderCard />} />
                            <Route path="/header-card/add" element={<HeaderCardAdd />} />
                            <Route path="/header-card/edit/:id" element={<HeaderCardAdd />} />
                            {/* comment */}
                            <Route path="/comment" element={<Comment />} />
                            <Route path="/comment/add" element={<CommentAdd />} />
                            <Route path="/comment/edit/:id" element={<CommentAdd />} />
                            {/* category */}
                            <Route path="/category" element={<Category />} />
                            <Route path="/category/add" element={<CategoryAdd />} />
                            <Route path="/category/edit/:id" element={<CategoryAdd />} />
                            {/* category */}
                            <Route path="/product" element={<Product />} />
                            <Route path="/product/add" element={<ProductAdd />} />
                            <Route path="/product/edit/:id" element={<ProductAdd />} />
                            {/* category */}
                            <Route path="/sertifkat" element={<Sertifkat />} />
                            <Route path="/sertifkat/add" element={<SertifkatAdd />} />
                            <Route path="/sertifkat/edit/:id" element={<SertifkatAdd />} />
                            {/* afzallik */}
                            <Route path="/advantage" element={<Advantage />} />
                            <Route path="/advantage/add" element={<AdvantageAdd />} />
                            <Route path="/advantage/edit/:id" element={<AdvantageAdd />} />

                            {/* statistics */}
                            <Route path="/statistics" element={<Statistics />} />
                            <Route path="/statistics/add" element={<StatisticsAdd />} />
                            <Route path="/statistics/edit/:id" element={<StatisticsAdd />} />

                            {/* about */}
                            <Route path="/about" element={<About />} />
                            <Route path="/about/add" element={<AboutAdd />} />
                            <Route path="/about/edit/:id" element={<AboutAdd />} />

                            <Route path="/price-list" element={<PriceList />} />
                            <Route path="/price-list/add" element={<PriceListAdd />} />
                            <Route path="/price-list/edit/:id" element={<PriceListAdd />} />



                            <Route path="/contact" element={<Contact />} />

                            {/* <Route path="/comments" element={<Comment />} />
                            <Route path="/comments/add" element={<CommentAdd />} />
                            <Route path="/comments/edit/:id" element={<CommentAdd />} /> */}




                            <Route path="/profile" element={<Profile />} />
                            <Route path="/profile/password-change" element={<PasswordChangePage />} />
                        </>
                        :
                        <Route path="/" element={<Login />} />
                }
                <Route path="*" element={<NotFound />} />


            </Routes>
        </>
    );
}

export default RouteLayout;
