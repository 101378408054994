import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    aboutData: any;
}


export const isAboutGet = createAsyncThunk('About', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/about');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    aboutData: []
}
export const AboutReducer = createSlice({
    name: 'About',
    initialState,
    reducers: {
        isAddAbout: (state, { payload }) => {
            state.aboutData.data = [...state.aboutData.data, payload]
        },
        isAddAllAbout: (state, { payload }) => {
            state.aboutData = payload
        },
        isDeleteAbout: (state, { payload }) => {
            state.aboutData.data = state.aboutData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isEditAbout: (state, { payload }) => {
            state.aboutData.data = state.aboutData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isAboutGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isAboutGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.aboutData = action.payload;
            state.isSuccess = true;
        },
        [isAboutGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const { isAddAbout, isDeleteAbout, isEditAbout, isAddAllAbout } = AboutReducer.actions

export default AboutReducer.reducer