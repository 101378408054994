import axios from 'axios';
import  { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loadSvg from '../../ui/icon/load.svg'
import { AppDispatch } from '../store';
import { isLoadingFunction, isLogoutFunction, userGet } from '../reducer/ProfileReducer';
import { isContactGet } from '../reducer/ContactReducer';
import { isCommentGet } from '../reducer/CommentReducer';
import { isCategoryGet } from '../reducer/CategoryReducer';
import { isProductGet } from '../reducer/ProductReducer';
import { isSertifkatGet } from '../reducer/SertifkatReducer';
import { isAdvantageGet } from '../reducer/AdvantageReducer';
import { isStatisticsGet } from '../reducer/StatisticsReducer';
import { isAboutGet } from '../reducer/AboutReducer';
import { isHeaderGet } from '../reducer/HeaderReducer';
import { isHeaderCardGet } from '../reducer/HeaderCardReducer';
import { isPriceListGet } from '../reducer/PriceListReducer';
export const Config = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { isLoading, token } = useSelector((state: any) => state.ProfileReducer)
    const path = useNavigate()
    const isUserLoaded = async () => {
        try {
            if (token?.length ?? 0 > 0) {
                dispatch(isLoadingFunction(true))
                let res = await axios.get('/user')
                dispatch(userGet({...res?.data?.data}))
            }
        }
        catch (err) {
            dispatch(isLogoutFunction())
            path('/')
        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    
    useEffect(() => {
        if(token?.length ?? 0 > 0){
            dispatch(isContactGet())
            dispatch(isSertifkatGet())
            dispatch(isCommentGet())
            dispatch(isCategoryGet())
            dispatch(isProductGet())
            dispatch(isAdvantageGet())
            dispatch(isStatisticsGet())
            dispatch(isAboutGet())
            dispatch(isHeaderGet())
            dispatch(isHeaderCardGet())
            dispatch(isPriceListGet())
        }
    }, [])
  
    useEffect(() => {
        isUserLoaded()
    }, [])
    return (
        <>
            <div className={`load   ${isLoading === true ? '' : 'active'}`}>
                <img src={loadSvg} alt="" />
            </div>
        </>
    )

        ;
}


