import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserTypeState } from '../../interface/interface';
import { CKEditor } from 'ckeditor4-react';

import LeftIcon from '../../ui/icon/LeftIcon';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux-toolkit/store';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import FormLoader from '../../loading/FormLoader';
import { isAddPriceList, isEditPriceList } from '../../redux-toolkit/reducer/PriceListReducer';
import { isProductGet } from '../../redux-toolkit/reducer/ProductReducer';
function PriceListAdd() {
    type QuizParams = {
        id: string;
    };
    const APi_url = 'https://backend.nambiotest.com/public/api/admin/server-photo'

    const { id } = useParams<QuizParams>();
    const [data, setData] = useState<UserTypeState>({});
    const path = useNavigate();


    const dispatch = useDispatch<AppDispatch>()
    const [error] = useState({
        status: '',
        usd: '',
        rubl: '',
        discount_usd: '',
        discount_rubl: '',
        discount_uzs: '',
        sell_usd: '',
        sell_rubl: '',

    })

    const schema = yup
        .object()
        .shape({
            title_uz: yup.string().required('Uzbek tilidagi sarlavhani   kiriting!'),
            // title_ru: yup.string().required('Rus tilidagi sarlavhani   kiriting!'),
            title_en: yup.string().required('Ingliz tilidagi sarlavhani   kiriting!'),
            status: yup.string().required("Holat tanlang!"),
        })
        .required();
    const { isFindData } = useSelector((state: any) => state.ProfileReducer
    );
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...isFindData
        }
    });

    const [loading, setLoading] = useState(false)
    const refresh = async () => {
        try {
            if ((id?.length ?? 0 > 0) && isFindData?.id === null) {
                setLoading(true)
                let res = await axios.get('/admin/price-list/' + id)
                console.log(res.data);

                if (res.status === 200) {
                    dispatch(isFindFunction(res?.data?.data))
                    for (let key in res.data?.data) {
                        setValue(key, res.data?.data[key], {
                            shouldValidate: true,
                        });
                    }
                }
            }
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }
    const send = async (data: any) => {
        try {
            dispatch(isLoadingFunction(true))
            if (id?.length ?? 0 > 0) {
                let res = await axios.put('/admin/price-list/' + id, data)
                if ((res).data.status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Kategoriya tahrirlandi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isEditPriceList(res?.data?.data))
                    dispatch(isProductGet())
                    path('/price-list')

                }
            } else {
                let res = await axios.post('/admin/price-list', data)
                if ((res).data.status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Kategoriya qo'shildi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isAddPriceList(res.data?.data))
                    path('/price-list')
                }


            }
        }
        catch (err) {

        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    useEffect(() => {
        refresh()
    }, [])

    return (
        <div className='contant padding-unset'>
            <div className='bg-white user-profile-container bg-white'>
                <div className="user-header flex between">
                    <Link to='/price-list' >
                        <h1 className='title flex center-align text-black'>
                            <LeftIcon />
                            {
                                id?.length ?? 0 > 0 ? "Kategoriya tahrirlash" : "Kategoriya qo'shish"
                            }
                        </h1>
                    </Link>
                </div>
                <br />
                {
                    loading ? <FormLoader /> :
                        <form className='form-group' onSubmit={handleSubmit(send)} >
                            <div className='grid grid-3 gap'>
                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(uz)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(uz)'
                                        {...register('title_uz')}
                                        name='title_uz'
                                    />
                                    <span className="input-group-error">{errors.title_uz?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                {/* <div className="form-input">
                                    <label htmlFor="username">Sarlavha(ru)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(ru)'
                                        {...register('title_ru')}
                                        name='title_ru'
                                    />
                                    <span className="input-group-error">{errors.title_ru?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div> */}
                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(en)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(en)'
                                        {...register('title_en')}
                                        name='title_en'
                                    />
                                    <span className="input-group-error">{errors.title_en?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Holati</label>
                                    <select
                                        className='form-control select'
                                        {...register('status')}
                                        name='status'
                                    >
                                        <option value="0" className='form-control'>Nofaol</option>
                                        <option value="1" className='form-control'>Faol</option>
                                    </select>
                                    <span className="input-group-error">{errors.status?.message?.toString()}</span>
                                    <span className="input-group-error">{error.status?.toString()}</span>
                                </div>
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(En)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.description_en ?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('description_en', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                />
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(Uz)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.description_uz?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('description_uz', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                />
                            </div>

                            <br />
                            <button className='white btn bg-green'>
                                {
                                    id?.length ?? 0 > 0 ? "Saqlash" : "Qo'shish"
                                }
                            </button>
                        </form>
                }

            </div>
        </div>
    );
}

export default PriceListAdd;
