import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    statisticsData: any;
}


export const isStatisticsGet = createAsyncThunk('statistics', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/statistics');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: true,
    isLoading: false,
    hasError: false,
    massage: '',
    statisticsData: [
       
    ]
}
export const StatisticsReducer = createSlice({
    name: 'statistics',
    initialState,
    reducers: {

        isDeleteStatistics: (state, { payload }) => {
            state.statisticsData.data = state.statisticsData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isAddStatistics: (state, { payload }) => {
            state.statisticsData.data = [...state.statisticsData.data, payload]
        },
        isAddAllStatistics: (state, { payload }) => {
            state.statisticsData = payload
        },
        isEditStatistics: (state, { payload }) => {
            state.statisticsData.data = state.statisticsData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isStatisticsGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isStatisticsGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.statisticsData = action.payload;
            state.isSuccess = true;
        },
        [isStatisticsGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const {isEditStatistics, isDeleteStatistics,isAddAllStatistics,isAddStatistics } = StatisticsReducer.actions

export default StatisticsReducer.reducer