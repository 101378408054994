import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserTypeState } from '../../interface/interface';

import LeftIcon from '../../ui/icon/LeftIcon';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux-toolkit/store';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import FormLoader from '../../loading/FormLoader';
import { isAddStatistics, isEditStatistics } from '../../redux-toolkit/reducer/StatisticsReducer';
import { isProductGet } from '../../redux-toolkit/reducer/ProductReducer';
function StatisticsAdd() {
    type QuizParams = {
        id: string;
    };
    const { id } = useParams<QuizParams>();
    const [data, setData] = useState<UserTypeState>({});
    const path = useNavigate();


    const dispatch = useDispatch<AppDispatch>()
    const [error] = useState({
        status: '',
        usd: '',
        rubl: '',
        discount_usd: '',
        discount_rubl: '',
        discount_uzs: '',
        sell_usd: '',
        sell_rubl: '',

    })
    const [file, setFile] = useState(null)
    const schema = yup
        .object()
        .shape({
            title_uz: yup.string().required('Uzbek tilidagi sarlavhani   kiriting!'),
            // title_ru: yup.string().required('Rus tilidagi sarlavhani   kiriting!'),
            title_en: yup.string().required('Ingliz tilidagi sarlavhani   kiriting!'),
            header_uz: yup.string().required('Uzbek tilidagi mahslot haqida malumot    kiriting!'),
            // header_ru: yup.string().required('Rus tilidagi mahslot haqida malumot    kiriting!'),
            header_en: yup.string().required('Ingliz tilidagi mahslot haqida malumot    kiriting!'),
            status: yup.string().required("Holat tanlang!"),
        })
        .required();
    const { isFindData } = useSelector((state: any) => state.ProfileReducer
    );
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...isFindData
        }
    });

    const [loading, setLoading] = useState(false)
    const refresh = async () => {
        try {
            if ((id?.length ?? 0 > 0) && isFindData?.id === null) {
                setLoading(true)
                let res = await axios.get('/admin/statistics/' + id)
                console.log(res.data);
                if (res.status===200) {
                    dispatch(isFindFunction(res?.data?.data))
                    for (let key in res.data?.data) {
                        setValue(key, res.data?.data[key], {
                            shouldValidate: true,
                        });
                    }
                }
            }
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }
    const send = async (data: any) => {
        try {
            dispatch(isLoadingFunction(true))
            if (id?.length ?? 0 > 0) {


                let res = await axios.put('/admin/statistics/' + id, { ...data, type: '0' })
                if ((res).status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Statistika tahrirlandi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isEditStatistics(res?.data?.data))
                    dispatch(isProductGet())
                    path('/statistics')

                }
            } else {

                let res = await axios.post('/admin/statistics', { ...data, type: '0' })
                if ((res).status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Statistika qo'shildi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isAddStatistics(res.data?.data))
                    path('/statistics')
                }


            }
        }
        catch (err) {

        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    useEffect(() => {
        refresh()
    }, [])

    return (
        <div className='contant padding-unset'>
            <div className='bg-white user-profile-container bg-white'>
                <div className="user-header flex between">
                    <Link to='/statistics' >
                        <h1 className='title flex center-align text-black'>
                            <LeftIcon />
                            {
                                id?.length ?? 0 > 0 ? "Statistika tahrirlash" : "Statistika qo'shish"
                            }
                        </h1>
                    </Link>
                </div>
                <br />
                {
                    loading ? <FormLoader /> :
                        <form className='form-group' onSubmit={handleSubmit(send)} >
                            <div className='grid grid-2 gap'>
                                <div className="form-input">
                                    <label htmlFor="username">Nomi(uz)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Nomi(uz)'
                                        {...register('title_uz')}
                                        name='title_uz'
                                    />
                                    <span className="input-group-error">{errors.title_uz?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                {/* <div className="form-input">
                                    <label htmlFor="username">Nomi(ru)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Nomi(ru)'
                                        {...register('title_ru')}
                                        name='title_ru'
                                    />
                                    <span className="input-group-error">{errors.title_ru?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div> */}
                                <div className="form-input">
                                    <label htmlFor="username">Nomi(en)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Nomi(en)'
                                        {...register('title_en')}
                                        name='title_en'
                                    />
                                    <span className="input-group-error">{errors.title_en?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(uz)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(uz)'
                                        {...register('header_uz')}
                                        name='header_uz'
                                    />
                                    <span className="input-group-error">{errors.header_uz?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                {/* <div className="form-input">
                                    <label htmlFor="username">Sarlavha(ru)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(ru)'
                                        {...register('header_ru')}
                                        name='header_ru'
                                    />
                                    <span className="input-group-error">{errors.header_ru?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div> */}
                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(en)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Sarlavha(en)'
                                        {...register('header_en')}
                                        name='header_en'
                                    />
                                    <span className="input-group-error">{errors.header_en?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                            </div>
                            <div className="grid grid-2 gap">
                            <div className="form-input">
                                        <label htmlFor="username">Soni</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            placeholder='soni'
                                            {...register('photo')}
                                            name='photo'
                                        />
                                        <span className="input-group-error">{errors.photo?.message?.toString()}</span>
                                        <span className="input-group-error">{error.usd?.toString()}</span>
                                    </div>
                                <div className="form-input">
                                    <label htmlFor="username">Holati</label>
                                    <select
                                        className='form-control select'
                                        {...register('status')}
                                        name='status'
                                    >
                                        <option value="0" className='form-control'>Nofaol</option>
                                        <option value="1" className='form-control'>Faol</option>
                                    </select>
                                    <span className="input-group-error">{errors.status?.message?.toString()}</span>
                                    <span className="input-group-error">{error.status?.toString()}</span>
                                </div>
                            </div>
                            <br />
                            <button className='white btn bg-green'>
                                {
                                    id?.length ?? 0 > 0 ? "Saqlash" : "Qo'shish"
                                }
                            </button>
                        </form>
                }

            </div>
        </div>
    );
}

export default StatisticsAdd;
