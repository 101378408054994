import  { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function NotFound() {
    const path = useNavigate()
    useEffect(() => {
        path('/')
        Swal.fire({
            position: 'top-end',
            title: 'Ushbu sahifda topilmadi!',
            icon: 'warning',
            timer: 2500,
            showConfirmButton: false,
        })
    }, [])
    return null;
}

export default NotFound;
