import  { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux-toolkit/store';
import { isLoadingFunction, profileRules } from '../redux-toolkit/reducer/ProfileReducer';
import eyeon from '../ui/icon/eye-pas-on.svg'
import eyeoff from '../ui/icon/eye-pas-off.svg'
import Swal from 'sweetalert2';
import { isContactGet } from '../redux-toolkit/reducer/ContactReducer';
import { isSertifkatGet } from '../redux-toolkit/reducer/SertifkatReducer';
import { isCommentGet } from '../redux-toolkit/reducer/CommentReducer';
import { isCategoryGet } from '../redux-toolkit/reducer/CategoryReducer';
import { isProductGet } from '../redux-toolkit/reducer/ProductReducer';
import { isAdvantageGet } from '../redux-toolkit/reducer/AdvantageReducer';
import { isStatisticsGet } from '../redux-toolkit/reducer/StatisticsReducer';
import { isAboutGet } from '../redux-toolkit/reducer/AboutReducer';
import { isHeaderGet } from '../redux-toolkit/reducer/HeaderReducer';
import { isHeaderCardGet } from '../redux-toolkit/reducer/HeaderCardReducer';
import { isPriceListGet } from '../redux-toolkit/reducer/PriceListReducer';
function Login() {
  const [eye, setEye] = useState({
    eye1: false,
  })
  const schema = yup
    .object()
    .shape({
      email: yup.string().required("email kiriting!"),
      password: yup.string().min(8, "Parol kamida 8ta belgidan iborat bo'lsin!").max(20, "Parol ko'pi 20ta belgidan iborat bo'lsin!").required("Parol kiriting!"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch<AppDispatch>()
  const login = async (data: any) => {
    try {
      dispatch(isLoadingFunction(true))
      let res = await axios.post('/login-admin', data)
      if (res.status === 200) {
        dispatch(profileRules({ ...res.data, ...res.data?.user }))
        Swal.fire({
          position: 'top-end',
          title: 'Tabriklaymiz!',
          text: res.data.message,
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + res.data?.token;
          dispatch(isContactGet())
          dispatch(isSertifkatGet())
          dispatch(isCommentGet())
          dispatch(isCategoryGet())
          dispatch(isProductGet())
          dispatch(isAdvantageGet())
          dispatch(isStatisticsGet())
          dispatch(isAboutGet())
          dispatch(isHeaderGet())
          dispatch(isHeaderCardGet())
          dispatch(isPriceListGet())
      }
    }
    catch (err: any) {
      const { non_field_errors } = err?.response?.data?.errors ?? false
      Swal.fire({
        position: 'top-end',
        title: non_field_errors ? "Login raqam yoki parol xato" : "Server bilan ulanib bo'lmadi",
        icon: 'warning',
        timer: 1500,
        showConfirmButton: false,
      })

    }
    finally {
      dispatch(isLoadingFunction(false))
    }
  }
  return (
    <div className='login'>
      <div className="login-item radius">
        <div className="login-logo">
          <LazyLoadImage
            src='./nambiotestlogo.svg'
            style={{ height: "160px", objectFit: 'contain' }}
            effect="blur"
          />
          <h1>Tizimga kirish</h1>
          <form className='form-group' onSubmit={handleSubmit(login)} >
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type='email'
                className='form-control'
                placeholder='Email'
                {...register('email')}
                name='email'
              />
              <span className="input-group-error">{errors.email?.message?.toString()}</span>
            </div>

            <div className="form-input">
              <label htmlFor="email">Parol</label>
              <div className="icon">
                <input
                  type={eye.eye1 ? 'text' : 'password'}
                  className='form-control'
                  placeholder='********'
                  {...register('password')}
                  name='password'
                />
                <button onClick={() => setEye({ ...eye, eye1: !eye.eye1 })} type="button" > <img src={eye.eye1 ? eyeon : eyeoff} alt="search" /></button>
              </div>
              <span className="input-group-error">{errors.password?.message?.toString()}</span>
            </div>
            <button className='white btn bg-green'>Kirish</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
