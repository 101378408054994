import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    productData: any;
}


export const isProductGet = createAsyncThunk('Product', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/product');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: true,
    isLoading: false,
    hasError: false,
    massage: '',
    productData: []
}
export const ProductReducer = createSlice({
    name: 'Product',
    initialState,
    reducers: {
        isAddProduct: (state, { payload }) => {
            state.productData.data = [...state.productData.data, payload]
        },
        isAddAllProduct: (state, { payload }) => {
            state.productData = payload
        },
        isDeleteProduct: (state, { payload }) => {
            state.productData.data = state.productData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isEditProduct: (state, { payload }) => {
            state.productData.data = state.productData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isProductGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isProductGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.productData = action.payload;
            state.isSuccess = true;
        },
        [isProductGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const { isAddProduct, isDeleteProduct, isEditProduct, isAddAllProduct } = ProductReducer.actions

export default ProductReducer.reducer