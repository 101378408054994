import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import Crk from './Crk';
import Home from './page/Home';
import RouteLayout from './page/layout/RouteLayout';
import Profile from './page/Profile';

function App() {
  const { isLoading, token, rules } = useSelector((state: any) => state.ProfileReducer)
  axios.defaults.headers.common['Authorization'] =
    token?.length > 0 ?
      'Bearer ' + token : '';

  return (
    <>
      <RouteLayout />
    </>
  );
}

export default App;
