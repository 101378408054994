import React from 'react';
import ReactDOM from 'react-dom/client';
import './ui/main.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-toastify/dist/ReactToastify.css';
// import reportWebVitals from './reportWebVitals';
// import "slick-carousel/slick/slick.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux-toolkit/store';
import axios from 'axios';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// axios.defaults.baseURL = 'https://api.dcserver-1.ru/';
// axios.defaults.baseURL = 'https://api.nammqial.uz/public/api/';
axios.defaults.baseURL = 'https://backend.nambiotest.com/public/api/';
root.render(
  <React.StrictMode>
    <Provider store={store}>
     <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
