import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserTypeState } from '../../interface/interface';

import LeftIcon from '../../ui/icon/LeftIcon';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux-toolkit/store';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import FormLoader from '../../loading/FormLoader';
import { isAddSertifkat, isEditSertifkat } from '../../redux-toolkit/reducer/SertifkatReducer';
import { isProductGet } from '../../redux-toolkit/reducer/ProductReducer';
function SertifkatAdd() {
    type QuizParams = {
        id: string;
    };
    const { id } = useParams<QuizParams>();
    const [data, setData] = useState<UserTypeState>({});
    const path = useNavigate();
    const [file, setFile] = useState(null)


    const dispatch = useDispatch<AppDispatch>()
    const [error] = useState({
        status: '',
        usd: '',
        rubl: '',
        discount_usd: '',
        discount_rubl: '',
        discount_uzs: '',
        sell_usd: '',
        sell_rubl: '',

    })

    const schema = yup
        .object()
        .shape({
            name: yup.string().required('Uzbek tilidagi sarlavhani   kiriting!'),
            status: yup.string().required("Holat tanlang!"),
            photo: yup.string().required('rasm malumot    kiriting!'),
        })
        .required();
    const { isFindData } = useSelector((state: any) => state.ProfileReducer
    );
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...isFindData
        }
    });

    const [loading, setLoading] = useState(false)
    const refresh = async () => {
        try {
            if ((id?.length ?? 0 > 0) && isFindData?.id === null) {
                setLoading(true)
                let res = await axios.get('/admin/sertifkat/' + id)
                console.log(res.data);

                if (res.status===200) {
                    dispatch(isFindFunction(res?.data?.data))
                    for (let key in res.data?.data) {
                        setValue(key, res.data?.data[key], {
                            shouldValidate: true,
                        });
                    }
                }
            }
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }
    const send = async (data: any) => {
        try {
            dispatch(isLoadingFunction(true))
            if (id?.length ?? 0 > 0) {

                let formData = new FormData(); 
                formData.append('status', data.status);
                formData.append('name', data.name);
                if (file !== null) {
                    formData.append('photo', file || '{}');
                }
                let res = await axios.post('/admin/sertifkat/' + id, formData)
                if ((res).data.status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Sertifkat tahrirlandi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isEditSertifkat(res?.data?.data))
                    dispatch(isProductGet())
                    path('/sertifkat')

                }
            } else {
                let formData = new FormData(); 
                formData.append('status', data.status);
                formData.append('name', data.name);
                if (file !== null) {
                    formData.append('photo', file || '{}');
                }
                let res = await axios.post('/admin/sertifkat', formData)
                if ((res).data.status=== 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Sertifkat qo'shildi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isAddSertifkat(res.data?.data))
                    path('/sertifkat')
                }


            }
        }
        catch (err) {

        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    useEffect(() => {
        refresh()
    }, [])

    return (
        <div className='contant padding-unset'>
            <div className='bg-white user-profile-container bg-white'>
                <div className="user-header flex between">
                    <Link to='/sertifkat' >
                        <h1 className='title flex center-align text-black'>
                            <LeftIcon />
                            {
                                id?.length ?? 0 > 0 ? "Sertifkat tahrirlash" : "Sertifkat qo'shish"
                            }
                        </h1>
                    </Link>
                </div>
                <br />
                {
                    loading ? <FormLoader /> :
                        <form className='form-group' onSubmit={handleSubmit(send)} >
                            <div className='grid grid-3 gap'>
                                <div className="form-input">
                                    <label htmlFor="username">nomi(uz)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='nomi(uz)'
                                        {...register('name')}
                                        name='name'
                                    />
                                    <span className="input-group-error">{errors.name?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                <div className="file form-input">
                                    <label htmlFor="file">
                                        <p>Rasmni yuklang</p>
                                        <div className="file_img">
                                            {
                                                id?.length ?? 0 > 0 ? <img src={file !== null ? URL.createObjectURL(file) : isFindData?.photo} alt="Rasm topilmadi" /> :
                                                    file === null ?

                                                        <>
                                                            <svg width={40} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" /></svg>
                                                            <br />
                                                            Yuklash
                                                        </>
                                                        : <img src={URL.createObjectURL(file)} alt="" />
                                            }
                                        </div>
                                    </label>
                                    <input type="file" onChange={(e: any) => {
                                       if(e.target.files[0]){
                                        setFile(()=>e.target.files[0])
                                        setValue('photo', '1', {
                                            shouldValidate: true,
                                        });

                                    }
                                    }} id="file" />
                                    <br />
                                    <br />
                                    <br />
                                    <span className="input-group-error">{errors.photo?.message?.toString()}</span>
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Holati</label>
                                    <select
                                        className='form-control select'
                                        {...register('status')}
                                        name='status'
                                    >
                                        <option value="0" className='form-control'>Nofaol</option>
                                        <option value="1" className='form-control'>Faol</option>
                                    </select>
                                    <span className="input-group-error">{errors.status?.message?.toString()}</span>
                                    <span className="input-group-error">{error.status?.toString()}</span>
                                </div>
                            </div>

                            <br />
                            <button className='white btn bg-green'>
                                {
                                    id?.length ?? 0 > 0 ? "Saqlash" : "Qo'shish"
                                }
                            </button>
                        </form>
                }

            </div>
        </div>
    );
}

export default SertifkatAdd;
