import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import eyeon from '../../ui/icon/eye-pas-on.svg'
import eyeoff from '../../ui/icon/eye-pas-off.svg'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LeftIcon from '../../ui/icon/LeftIcon';
import { AppDispatch } from "../../redux-toolkit/store";
import { isLoadingFunction } from "../../redux-toolkit/reducer/ProfileReducer";
import Swal from "sweetalert2";
import { useState } from "react";

function PasswordChange() {
  const schema = yup
    .object()
    .shape({
      old_password: yup.string().min(8, "Parol kamida 8ta belgidan iborat bo'lsin!").max(20, "Parol ko'pi 20ta belgidan iborat bo'lsin!").required("Parol kiriting!"),
      new_password: yup.string().min(8, "Parol kamida 8ta belgidan iborat bo'lsin!").max(20, "Parol ko'pi 20ta belgidan iborat bo'lsin!").required("Parol kiriting!"),
      con_new_password: yup.string().required("Parol kiritng kiriting!").min(8, "Parol 8ta belgidan kam bo'lmasligi kerak!").oneOf([yup.ref('new_password')], "Parol mos kelmadi!"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch<AppDispatch>()
  const path = useNavigate()
  const [eye, setEye] = useState({
    eye1: false,
    eye2: false,
    eye3: false,
  })
  const send = async (data: any) => {
    dispatch(isLoadingFunction(true))
    try {
      let res = await axios.post('/change-password', data)
      if (res.data.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000
        })
        path("/")
      }
    }
    catch (er: any) {
      Swal.fire({
        position: 'top-end',
        icon: 'info',
        title: "Hatolik",
        showConfirmButton: false,
        timer: 2000
      })
    }
    finally {
      dispatch(isLoadingFunction(false))
    }

  }
  return (
    <div className='profile-edit user-profile-container bg-white'>
      <Link to='/profile' >
        <h1 className='title flex center-align text-black'>
          <LeftIcon />
          Parolni ozgartirish</h1>
      </Link>
      <form className='form-group ' onSubmit={handleSubmit(send)}  >
        <div className="form-input">
          <label htmlFor="username">Oldingi parolni kiriting</label>
          <div className="icon">
            <input
              type={eye.eye1 ? 'text':'password'}
              className='form-control'
              placeholder='********'
              {...register('old_password')}
              name='old_password'
            />
            <button onClick={()=>setEye({...eye, eye1:!eye.eye1})} type="button" > <img src={eye.eye1 ? eyeon : eyeoff} alt="search" /></button>
          </div>
          <span className="input-group-error">{errors?.old_password?.message?.toString()}</span>

        </div>
        <div className="form-input">
          <label htmlFor="username">Yangi parolni kiriting</label>
          <div className="icon">
            <input
              type={eye.eye2 ? 'text' : 'password'}
              className='form-control'
              placeholder='********'
              {...register('new_password')}
              name='new_password'
            />
            <button onClick={()=>setEye({...eye, eye2:!eye.eye2})} type="button" > <img src={eye.eye2 ? eyeon : eyeoff} alt="search" /></button>
          </div>
          <span className="input-group-error">{errors?.new_password?.message?.toString()}</span>
        </div>
        <div className="form-input">
          <label htmlFor="username">Yangi parolni takroran kiriting</label>
         <div className="icon">
         <input
            type={eye.eye3 ? 'text' : 'password'}
            className='form-control'
            placeholder='********'
            {...register('con_new_password')}
            name='con_new_password'
          />
            <button onClick={()=>setEye({...eye, eye3:!eye.eye3})} type="button" > <img src={eye.eye3 ? eyeon : eyeoff} alt="search" /></button>
         </div>
          <span className="input-group-error">{errors?.con_new_password?.message?.toString()}</span>
        </div>
        <button className='white btn bg-green'>Saqlash</button>
      </form>
    </div>
  );
}

export default PasswordChange;
