import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    headerCardData: any;
}


export const isHeaderCardGet = createAsyncThunk('HeaderCard', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/header-card');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    headerCardData: []
}
export const HeaderCardReducer = createSlice({
    name: 'HeaderCard',
    initialState,
    reducers: {
        isAddHeaderCard: (state, { payload }) => {
            state.headerCardData.data = [...state.headerCardData.data, payload]
        },
        isAddAllHeaderCard: (state, { payload }) => {
            state.headerCardData = payload
        },
        isDeleteHeaderCard: (state, { payload }) => {
            state.headerCardData.data = state.headerCardData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isEditHeaderCard: (state, { payload }) => {
            state.headerCardData.data = state.headerCardData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isHeaderCardGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isHeaderCardGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.headerCardData = action.payload;
            state.isSuccess = true;
        },
        [isHeaderCardGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const { isAddHeaderCard, isDeleteHeaderCard, isEditHeaderCard, isAddAllHeaderCard } = HeaderCardReducer.actions

export default HeaderCardReducer.reducer