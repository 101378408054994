import axios from 'axios';
import  { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { AppDispatch } from '../../redux-toolkit/store';
import DeleteIcon from '../../ui/icon/DeleteIcon';
import LeftIcon from '../../ui/icon/LeftIcon';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import { isAddAllContact, isDeleteContact, isEditContact } from '../../redux-toolkit/reducer/ContactReducer';
import eyeon from '../../ui/icon/serach.svg'
import TableLoader from '../../loading/TableLoader';
function Contact() {
   


    const { contactData, isSuccess } = useSelector((state: any) => state.ContactReducer);
    const dispatch = useDispatch<AppDispatch>()

    const handlePageClick = async (e: any) => {
        try {

            dispatch(isLoadingFunction(true))
            let res = await axios.get(`/admin/contact?page=${e?.selected + 1}`);
            if (res.status === 200) {
                dispatch(isAddAllContact(res.data))
            }
        }
        catch (err) {
            Swal.fire({
                position: 'top-end',
                title: 'Hatolik!',
                text: "Malumot mavjud emas",
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
            })
        }
        finally {
            dispatch(isLoadingFunction(false))
        }

    }
    const path =  useNavigate()
    const send = async (data:any) => {
        try {
            dispatch(isLoadingFunction(true))
            let res = await axios.get(`/admin/contact/view/`+data?.id)
            if (res.status === 200) {
                dispatch(isFindFunction(res.data.data))
                dispatch(isEditContact(res.data.data))
                path(`/show/contact/${data?.id}`)
            }
        }
        catch (error) {
        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    const [searchValue, setSearchValue] = useState('')
    const serach = (data: any) => {
        if (searchValue?.length > 0) {
            return data?.filter((item: any) => {
                let res = item?.full_name?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.phone?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.message?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1
                return res
            })

        }
        else {
            return data
        }
    }

    return (
        <div className='contant padding-unset bg-white user-profile-container bg-white'>
            <div className="user-header flex between">
                <Link to='/' >
                    <h1 className='title flex center-align text-black'>
                        <LeftIcon />
                        Bo'g'lanishlar
                    </h1>
                </Link>
                <div className="form-input">
                    <div className="icon">
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Qidiruv...'
                            name='password'
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onKeyDown={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onKeyUp={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                        />
                        <button > <img src={eyeon} alt="search" /></button>
                    </div>
                </div >
            </div>
            {isSuccess ?

                <div className="user-body">
                    <div className="resposive-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>F.I.O</th>
                                    <th>Telefon</th>
                                    <th>Fikr</th>
                                    <th>Holati</th>
                                    <th>Activaion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    serach(contactData?.data)?.length > 0 ?

                                        serach(contactData?.data)?.map((item: any, index: number) => (
                                            <tr key={item?.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {
                                                        item?.status ? 
                                                    <Link className='comment_' to={`/show/contact/${item?.id}`}
                                                        onClick={() => {
                                                            dispatch(isFindFunction(item))
                                                        }}
                                                    >
                                                        {item?.full_name}
                                                    </Link> : <button onClick={()=>send(item)} className='btn white bg-red'>
                                                        O'qish
                                                    </button>
                                                    }
                                                </td>
                                                <td>{item?.phone}</td>
                                                <td className='comment_'>{item?.message}</td>
                                                <td>{item?.status ? <p className='bg-green text-center  white'>O'qilgan</p> : <p className='bg-red text-center white  text-white'>O'qilmagan</p>}</td>
                                                <td className='flex gap-050'>
                                                    <button className='btn bg-red btn-sm' onClick={() => {
                                                        Swal.fire({
                                                            title: "Malumotni o'chirasimi?",
                                                            showDenyButton: true,
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Ha',
                                                            denyButtonText: `Yo'q`,
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                dispatch(isLoadingFunction(true))
                                                                axios.delete(`/admin/contact/${item?.id}`)
                                                                    .then((res) => {
                                                                        if (res.data.status === 200) {
                                                                            Swal.fire({
                                                                                position: 'top-end',
                                                                                icon: 'success',
                                                                                title: "Izoh o'chirildi",
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            dispatch(isDeleteContact(item?.id))
                                                                        }
                                                                        dispatch(isLoadingFunction(false))
                                                                    })

                                                            } else if (result.isDenied) {
                                                                Swal.fire('Changes are not saved', '', 'info')
                                                            }
                                                        })
                                                    }}><DeleteIcon /></button>
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={6}><h2 className='text-center'>Malumot yo'q</h2></td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        {/* <p>pagination : {ValuteData?.last_page}   current:{page}</p> */}
                        {/* <Pagination total={ValuteData?.last_page + 5} count={counts} /> */}
                    </div>
                    <br />
                    {
                        contactData?.last_page > 1 ?
                            <ReactPaginate
                                className="pagination"
                                breakLabel="..."
                                nextLabel={`next >`}
                                onPageChange={handlePageClick}
                                prevRel={'salom'}
                                pageCount={contactData?.last_page}
                                previousLabel={`<previous `}
                            /> : ''
                    }
                </div> : <TableLoader />}
        </div>
    );
}

export default Contact;
