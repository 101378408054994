import UserProfile from '../components/profile/UserProfile';

function Profile() {
  return (
    <div className='contant padding-unset' id='p'>
      <UserProfile/>
    </div>
  );
}

export default Profile;
