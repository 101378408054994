import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AppDispatch } from '../../redux-toolkit/store';
import DeleteIcon from '../../ui/icon/DeleteIcon';
import EditIcon from '../../ui/icon/EditIcon';
import ReactPaginate from "react-paginate";
import LeftIcon from '../../ui/icon/LeftIcon';
import { isFindFunction, isLoadingFunction, loadFun, loadStart } from '../../redux-toolkit/reducer/ProfileReducer';
import eyeon from '../../ui/icon/serach.svg'
import {  isAddAllComment, isDeleteComment } from '../../redux-toolkit/reducer/CommentReducer';
function Comment() {
  const { commentData } = useSelector((state: any) => state.CommentReducer);
  const path = useNavigate();
  const dispatch = useDispatch<AppDispatch>()
  const handlePageClick = async (e: any) => {
    try {

      dispatch(isLoadingFunction(true))
      let res = await axios.get(`/admin/comment?page=${e?.selected + 1}`);
      if (res.status === 200) {
        dispatch(isAddAllComment(res.data))
      }
    }
    catch (err) {
      Swal.fire({
        position: 'top-end',
        title: 'Hatolik!',
        text: "Malumot mavjud emas",
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      })
    }
    finally {
      dispatch(isLoadingFunction(false))
    }

  }
  const [searchValue, setSearchValue] = useState('')
  const serach = (data: any) => {
    if (searchValue?.length > 0) {
      return data?.filter((item: any) => {
        let res = (item?.title_uz?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || 
        item?.title_ru?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1) ||item?.title_en?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1
        return res
      })

    }
    else {
      return data
    }
  }

  return (
    <div className='contant padding-unset bg-white user-profile-container bg-white'>
      <div className="user-header flex between">
        <Link to='/' >
          <h1 className='title flex center-align text-black'>
            <LeftIcon />
            Mijozlar fikri
          </h1>
        </Link>
        <div>
          <button className='btn bg-green white' onClick={() => {
            dispatch(isFindFunction({}))
            path('/comment/add')
          }}>Qo'shish</button>
        </div>
      </div>
      <div className="user-body">
        <div className="resposive-table">
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>link</th>
                <th>Holati</th>
                <th>Activaion</th>
              </tr>
            </thead>
            <tbody>
              {
                serach(commentData?.data)?.length > 0 ?
                  serach(commentData?.data)?.map((item: any, index: number) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td  ><Link className='comment_' to={`/show/comment/${item?.id}`}
                        onClick={() => {
                          dispatch(isFindFunction(item))
                        }}
                      >
                        {item?.video}
                      </Link>
                      </td>
                      <td>{item?.status === 1 ? 'Faol' : 'Nofaol'}</td>
                      <td className='flex gap-050'>
                        <button className='btn bg-green btn-sm' onClick={() => {
                          dispatch(isFindFunction(item))
                          path(`/comment/edit/${item?.id}`)
                        }}><EditIcon /></button>
                        <button className='btn bg-red btn-sm' onClick={() => {
                          Swal.fire({
                            title: "Malumotni o'chirasimi?",
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Ha',
                            denyButtonText: `Yo'q`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // dispatch(isDeleteComment(item?.id));
                              dispatch(isLoadingFunction(true))
                              axios.delete(`/admin/comment/${item?.id}`)
                                .then((res) => {

                                  if (res.data.status === 200) {
                                    Swal.fire({
                                      position: 'top-end',
                                      icon: 'success',
                                      title: "Comment o'chirildi",
                                      showConfirmButton: false,
                                      timer: 2500
                                    })
                                    dispatch(isDeleteComment(item?.id));
                                    dispatch(isLoadingFunction(false))
                                  }
                                  dispatch(isLoadingFunction(false))

                                })
                            } else if (result.isDenied) {
                              Swal.fire('Changes are not saved', '', 'info')
                            }
                          })
                        }}><DeleteIcon /></button>
                      </td>
                    </tr>
                  )) : <tr>
                    <td colSpan={8}><h2 className='text-center'>Malumot yo'q</h2></td>
                  </tr>

              }
            </tbody>
          </table>
          {/* <p>pagination :  current:{page}</p> */}
          {/* <Pagination total={20 + 5} count={countFun} url='comments' /> */}
        </div>
        <br />

          {
            commentData?.meta?.last_page > 1 ?
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel={`next >`}
                onPageChange={handlePageClick}
                prevRel={'salom'}
                pageCount={commentData?.meta?.last_page}
                previousLabel={`<previous `}
              /> : ''
          }
      </div>
    </div>
  );
}

export default Comment;
