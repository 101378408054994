import React from 'react';
type LayoutProps = {
    Component: React.ComponentType
}
function Contet({ Component }: LayoutProps) {
    return (
        <div className='contant padding-unset' id='p'>
            <Component />
        </div>
    );
}

export default Contet;
