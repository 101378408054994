import  {  useLayoutEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import users from '../../ui/icon/users.svg'
function UserProfile() {
  const select = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
  }, [select])
  const path = useNavigate()
  const { profile } = useSelector((state: any) => state.ProfileReducer)

  return (
    <div className='user-profile-container bg-white'>
      <div className="user-detil-all users">
        <h2 className='title-text'>Shaxsiy kabinet</h2>
        <br />
        <div className="flex user-about center-align">
          <div className="avatar-user">
            <LazyLoadImage
              className='cover'
              src={users}
              effect="blur"
            />
          </div>
          <div className="user-all-name">
            <h1>{profile?.username || profile?.data?.username}</h1>
            <div className="editors">
              <button className='btn bg-grey-50' onClick={() => path('/profile/password-change')}>Paroni o’zgartirish</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
