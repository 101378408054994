import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    priceListData: any;
}


export const isPriceListGet = createAsyncThunk('priceList', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/price-list');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    priceListData: [
       
    ]
}
export const PriceListReducer = createSlice({
    name: 'priceList',
    initialState,
    reducers: {

        isDeletePriceList: (state, { payload }) => {
            state.priceListData.data = state.priceListData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isAddPriceList: (state, { payload }) => {
            state.priceListData.data = [...state.priceListData.data, payload]
        },
        isAddAllPriceList: (state, { payload }) => {
            state.priceListData = payload
        },
        isEditPriceList: (state, { payload }) => {
            state.priceListData.data = state.priceListData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isPriceListGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isPriceListGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.priceListData = action.payload;
            state.isSuccess = true;
        },
        [isPriceListGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const {isEditPriceList, isDeletePriceList,isAddAllPriceList,isAddPriceList } = PriceListReducer.actions

export default PriceListReducer.reducer