import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import LeftIcon from '../../ui/icon/LeftIcon';
import { CKEditor } from 'ckeditor4-react';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux-toolkit/store';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import FormLoader from '../../loading/FormLoader';
import { isAddProduct, isEditProduct } from '../../redux-toolkit/reducer/ProductReducer';
function ProductAdd() {
    const APi_url = 'https://backend.nambiotest.com/public/api/admin/server-photo'
    const [imgUpload, setImgUpload] = useState({
        description: {
            old: '',
            currnt: ''
        },
        apply: {
            old: '',
            currnt: ''
        },
        instructions: {
            old: '',
            currnt: ''
        },
    })
    type QuizParams = {
        id: string;
    };
    const { id } = useParams<QuizParams>();
    const path = useNavigate();

    const dispatch = useDispatch<AppDispatch>()
    const [error] = useState({
        status: '',
        usd: '',
        rubl: '',
        discount_usd: '',
        discount_rubl: '',
        discount_uzs: '',
        sell_usd: '',
        sell_rubl: '',

    })

    const schema = yup
        .object()
        .shape({
            title_uz: yup.string().required('Uzbek tilidagi sarlavhani   kiriting!'),
            // title_ru: yup.string().required('Rus tilidagi sarlavhani   kiriting!'),
            title_en: yup.string().required('Ingliz tilidagi sarlavhani   kiriting!'),
            header_uz: yup.string().required('Uzbek tilidagi mahslot haqida malumot    kiriting!'),
            // header_ru: yup.string().required('Rus tilidagi mahslot haqida malumot    kiriting!'),
            header_en: yup.string().required('Ingliz tilidagi mahslot haqida malumot    kiriting!'),
            description_uz: yup.string().required('Uzbek tilidagi mahslot haqida to`liq malumot    kiriting!'),
            // description_ru: yup.string().required('Rus tilidagi mahslot haqida to`liq malumot    kiriting!'),
            description_en: yup.string().required('Ingliz tilidagi mahslot  to`liq haqida malumot    kiriting!'),
            // apply_uz: yup.string().required('Uzbek tilidagi mahslot haqida qollashni    kiriting!'),
            // apply_ru: yup.string().required('Rus tilidagi mahslot haqida  qollashni    kiriting!'),
            // apply_en: yup.string().required('Ingliz tilidagi mahslot haqida  qollashni    kiriting!'),
            // instructions_uz: yup.string().required('Uzbek tilidagi mahslot haqida instructions    kiriting!'),
            // instructions_ru: yup.string().required('Rus tilidagi mahslot haqida  instructions    kiriting!'),
            // instructions_en: yup.string().required('Ingliz tilidagi mahslot haqida  instructions    kiriting!'),
            discount: yup.number().typeError('Faqat raqam kiritng!'),
            category_id: yup.string().required('Maxsulot kategoriyasini kiriting!'),
            photo: yup.string().required('rasm malumot    kiriting!'),
            status: yup.string().required("Holat tanlang!"),
        })
        .required();
    const { isFindData } = useSelector((state: any) => state.ProfileReducer
    );
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...isFindData
        }
    });
    const [file, setFile] = useState(null)
    console.log(errors);

    const [loading, setLoading] = useState(false)
    const refresh = async () => {
        try {
            if ((id?.length ?? 0 > 0) && isFindData?.id === null) {
                setLoading(true)
                let res = await axios.get('/admin/product/' + id)
                if (res.status === 200) {
                    dispatch(isFindFunction(res.data?.data))
                    for (let key in res.data?.data) {
                        setValue(key, res.data?.data[key], {
                            shouldValidate: true,
                        });
                    }
                }
            }
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }
    const send = async (data: any) => {
        try {
            dispatch(isLoadingFunction(true))
            if (id?.length ?? 0 > 0) {
                let formData = new FormData();
                formData.append('header_ru', data.header_ru);
                formData.append('header_uz', data.header_uz);
                formData.append('header_en', data.header_en);
                formData.append('title_ru', data.title_ru);
                formData.append('title_uz', data.title_uz);
                formData.append('title_en', data.title_en);
                // formData.append('apply_ru', data.apply_ru);
                // formData.append('apply_uz', data.apply_uz);
                // formData.append('apply_en', data.apply_en);
                // formData.append('instructions_ru', data.instructions_ru);
                formData.append('instructions_uz', 'data.instructions_uz');
                formData.append('instructions_en', 'data.instructions_en');
                // formData.append('description_ru', data.description_ru);
                formData.append('description_uz', data.description_uz);
                formData.append('description_en', data.description_en);
                formData.append('price', data.price ?? 0);
                formData.append('discount', data.discount ?? 0);
                formData.append('category_id', data.category_id);
                formData.append('status', data.status);
                if (file !== null) {
                    formData.append('photo', file || '{}');
                }
                let res = await axios.post('/admin/product/' + id, formData)
                if ((res).status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Yangilik tahrirlandi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isEditProduct(res?.data?.data))
                    path('/product')

                }
            } else {
                let formData = new FormData();
                // formData.append('header_ru', data.header_ru);
                formData.append('header_uz', data.header_uz);
                formData.append('header_en', data.header_en);
                // formData.append('title_ru', data.title_ru);
                formData.append('title_uz', data.title_uz);
                formData.append('title_en', data.title_en);
                // formData.append('apply_ru', data.apply_ru);
                // formData.append('apply_uz', data.apply_uz);
                // formData.append('apply_en', data.apply_en);
                // formData.append('instructions_ru', data.instructions_ru);
                formData.append('instructions_uz', 'data.instructions_uz');
                formData.append('instructions_en', 'data.instructions_en');
                // formData.append('description_ru', data.description_ru);
                formData.append('description_uz', data.description_uz);
                formData.append('description_en', data.description_en);
                formData.append('price', data.price ?? 0);
                formData.append('discount', data?.discount ?? 0);
                formData.append('category_id', data.category_id);
                formData.append('status', data.status);
                if (file !== null) {
                    formData.append('photo', file || '{}');
                }
                let res = await axios.post('/admin/product', formData)
                if ((res).status === 200) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Tabriklaymiz!',
                        text: "Yangilik qo'shildi",
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    dispatch(isAddProduct(res.data.data))
                    path('/product')
                }

            }
        }
        catch (err) {

        }
        finally {
            dispatch(isLoadingFunction(false))
        }
    }
    useEffect(() => {
        refresh()
    }, [])
    const { categoryData, success } = useSelector((state: any) => state.CategoryReducer
    );
    return (
        <div className='contant padding-unset'>
            <div className='bg-white user-profile-container bg-white'>
                <div className="user-header flex between">
                    <Link to='/product' >
                        <h1 className='title flex center-align text-black'>
                            <LeftIcon />
                            {
                                id?.length ?? 0 > 0 ? "maxsulot tahrirlash" : "maxsulot qo'shish"
                            }
                        </h1>
                    </Link>
                </div>
                <br />
                {
                    loading ? <FormLoader /> :
                        <form className='form-group product' onSubmit={handleSubmit(send)} >
                            <div className="grid grid-2 gap">
                                <div className="form-input">
                                    <label htmlFor="username">Mahsulot nomi(uz)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Mahsulot nomi(uz)'
                                        {...register('title_uz')}
                                        name='title_uz'
                                    />
                                    <span className="input-group-error">{errors.title_uz?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Mahsulot nomi(en)</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Mahsulot nomi(en)'
                                        {...register('title_en')}
                                        name='title_en'
                                    />
                                    <span className="input-group-error">{errors.title_en?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                            </div>
                            <div className="grid grid-3 gap">
                                <div className="form-input">
                                    <label htmlFor="username">Kategoriyasi</label>
                                    <select
                                        className='form-control select'
                                        {...register('category_id')}
                                        name='category_id'
                                    >
                                        {
                                            categoryData?.data?.map((category: any, index: number) => (
                                                <option value={category?.id} className='form-control'>{category.title_uz}</option>
                                            ))
                                        }
                                    </select>
                                    <span className="input-group-error">{errors.category_id?.message?.toString()}</span>
                                    <span className="input-group-error">{error.status?.toString()}</span>
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Maxsulot narxi</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        placeholder="20000"
                                        {...register('price')}
                                        name='price'
                                    />
                                    <span className="input-group-error">{errors.price?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                <div className="form-input">

                                    <label htmlFor="username">Maxsulot narxi chegirmasini</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        placeholder="20000"
                                        {...register('discount')}
                                        name='discount'
                                    />
                                    <span className="input-group-error">{errors.discount?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                            </div>
                            <div className='grid grid-2 gap'>


                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(uz)</label>
                                    <textarea
                                        className='form-control'
                                        placeholder="Sarlavha(uz)"
                                        {...register('header_uz')}
                                        name='header_uz'
                                    ></textarea>
                                    <span className="input-group-error">{errors.header_uz?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                                {/* <div className="form-input">
                                    <label htmlFor="username">Sarlavha(ru)</label>
                                    <textarea
                                        className='form-control'
                                        placeholder="Sarlavha(ru)"
                                        {...register('header_ru')}
                                        name='header_ru'
                                    ></textarea>
                                    <span className="input-group-error">{errors.header_ru?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div> */}
                                <div className="form-input">
                                    <label htmlFor="username">Sarlavha(en)</label>
                                    <textarea
                                        className='form-control'
                                        placeholder="Sarlavha(en)"
                                        {...register('header_en')}
                                        name='header_en'
                                    ></textarea>
                                    <span className="input-group-error">{errors.header_en?.message?.toString()}</span>
                                    <span className="input-group-error">{error.usd?.toString()}</span>
                                </div>
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(En)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.description_en ?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('description_en', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                />
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(Uz)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.description_uz ?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('description_uz', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                />
                            </div>
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot instruksiya(En)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.instructions_en ?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('instructions_en', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                />
                            </div>
                            <div className="form-input"> */}
                            {/* <label htmlFor="username">Maxsulot instruksiya(Uz)</label>
                                <CKEditor
                                    debug={true}
                                    editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                    initData={isFindData?.instructions_uz?? '<p></p>'}
                                    config={
                                        {
                                            allowedContent: true,
                                            uploadUrl: APi_url,
                                            filebrowserUploadUrl: APi_url,
                                            filebrowserImageUploadUrl: APi_url,
                                            on: {
                                                change: (event: any) => {
                                                    setValue('instructions_uz', event.editor.getData(), {
                                                        shouldValidate: true,
                                                    });
                                                },
                                            }
                                        }

                                    }

                                    name="my-ckeditor"

                                    style={{ borderColor: 'blue' }
                                    }
                                    type="classic"
                                /> */}
                            {/* </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(en)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.description_en || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('description_en', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('description_ru', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('description_uz', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.description_en?.message?.toString()}</span>
                            </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(uz)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.description_uz || getValues('description_uz') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log('Editor is ready to use!', editor);

                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('description_uz', data, {
                                            shouldValidate: true,
                                        });

                                        console.log('description_uz', getValues('description_uz').indexOf('<img src="'));
                                        console.log(getValues('description_uz').slice(getValues('description_uz').indexOf('<figure'), getValues('description_uz').indexOf('</figure>')));


                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.description_uz?.message?.toString()}</span>
                            </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot haqida(ru)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.description_ru || getValues('description_ru') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('description_ru', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.description_ru?.message?.toString()}</span>
                            </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot instruksitsiya(en)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.instructions_en || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('instructions_en', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('instructions_uz', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('instructions_ru', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.instructions_en?.message?.toString()}</span>
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot instruksitsiya(uz)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.instructions_uz || getValues('instructions_uz') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('instructions_uz', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.instructions_uz?.message?.toString()}</span>
                            </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot instruksitsiya(ru)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.instructions_ru || getValues('instructions_ru') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('instructions_ru', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.instructions_ru?.message?.toString()}</span>
                            </div> */}
                            {/* <div className="form-input">
                                <label htmlFor="username">Maxsulot qo'llanilishi(en)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.apply_en || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('apply_en', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('apply_uz', data, {
                                            shouldValidate: true,
                                        });
                                        setValue('apply_ru', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.apply_en?.message?.toString()}</span>
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot qo'llanilishi(uz)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.apply_uz || getValues('apply_uz') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('apply_uz', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.apply_uz?.message?.toString()}</span>
                            </div>
                            <div className="form-input">
                                <label htmlFor="username">Maxsulot qo'llanilishi(ru)</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            ckfinder: {
                                                openerMethod: 'modal',
                                                uploadUrl: APi_url,
                                            }

                                        }}
                                    data={isFindData?.apply_ru || getValues('apply_ru') || ''}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('apply_ru', data, {
                                            shouldValidate: true,
                                        });

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <span className="input-group-error">{errors.apply_ru?.message?.toString()}</span>
                            </div> */}

                            <div className="grid grid-2 gap">
                                <div className="file form-input">
                                    <label htmlFor="file">
                                        <p>Rasmni yuklang</p>
                                        <div className="file_img">
                                            {
                                                id?.length ?? 0 > 0 ? <img src={file !== null ? URL.createObjectURL(file) : isFindData?.photo} alt="Rasm topilmadi" /> :
                                                    file === null ?

                                                        <>
                                                            <svg width={40} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" /></svg>
                                                            <br />
                                                            Yuklash
                                                        </>
                                                        : <img src={URL.createObjectURL(file)} alt="" />
                                            }
                                        </div>
                                    </label>
                                    <input type="file" onChange={(e: any) => {
                                        console.log(e.target.files[0]);
                                        if(e.target.files[0]){
                                            setFile(()=>e.target.files[0])
                                            setValue('photo', '1', {
                                                shouldValidate: true,
                                            });

                                        }
                                    }} id="file" />
                                    <br />
                                    <br />
                                    <br />
                                    <span className="input-group-error">{errors.photo?.message?.toString()}</span>
                                    {/* <span className="input-group-error">{error.photo?.toString()}</span> */}
                                </div>
                                <div className="form-input">
                                    <label htmlFor="username">Holati</label>
                                    <select
                                        className='form-control select'
                                        {...register('status')}
                                        name='status'
                                    >
                                        <option value="0" className='form-control'>Nofaol</option>
                                        <option value="1" className='form-control'>Faol</option>
                                    </select>
                                    <span className="input-group-error">{errors.status?.message?.toString()}</span>
                                    <span className="input-group-error">{error.status?.toString()}</span>
                                </div>
                            </div>


                            <br />
                            <button className='white btn bg-green'>
                                {
                                    id?.length ?? 0 > 0 ? "Saqlash" : "Qo'shish"
                                }
                            </button>
                        </form>
                }

            </div>
        </div>
    );
}

export default ProductAdd;
