import PasswordChange from '../components/profile/PasswordChange';

function PasswordChangePage() {
  return (
    <div className='contant padding-unset'>
      <PasswordChange/>
    </div>
  );
}

export default PasswordChangePage;
