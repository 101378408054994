import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ProfileReducer  from './reducer/ProfileReducer'

import ContactReducer  from './reducer/ContactReducer'
import PartnerReducer  from './reducer/PartnerReducer'
import CommentReducer  from './reducer/CommentReducer'
import CategoryReducer  from './reducer/CategoryReducer'
import ProductReducer  from './reducer/ProductReducer'
import SertifkatReducer  from './reducer/SertifkatReducer'
import AdvantageReducer  from './reducer/AdvantageReducer'
import StatisticsReducer  from './reducer/StatisticsReducer'
import AboutReducer  from './reducer/AboutReducer'
import HeaderReducer  from './reducer/HeaderReducer'
import HeaderCardReducer  from './reducer/HeaderCardReducer'
import PriceListReducer  from './reducer/PriceListReducer'
const store = configureStore({
    reducer: {
        HeaderCardReducer,
        CommentReducer,
        HeaderReducer,
        CategoryReducer,
        ProductReducer,
        SertifkatReducer,
        AdvantageReducer,
        StatisticsReducer,
        AboutReducer,
        ProfileReducer,
        ContactReducer,
        PartnerReducer,
        PriceListReducer,
    },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch()
// export type AppThunk = ThunkAction<void, unknown, Action>

export default store