import  { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import news from '../../ui/icon/news.svg'
import study from '../../ui/icon/study.svg'
import direction from '../../ui/icon/direction.svg'
import contact from '../../ui/icon/card/contact.svg'
import pricelist from '../../ui/icon/card/price-list.svg'
import comment from '../../ui/icon/card/comment.svg'
import product from '../../ui/icon/card/product.svg'
import category from '../../ui/icon/card/category.jpg'
import certificate from '../../ui/icon/card/certificate.svg'
import statistics from '../../ui/icon/card/statistics.svg'
import advantages from '../../ui/icon/card/advantages.svg'
import header from '../../ui/icon/card/header.svg'
import headerCard from '../../ui/icon/card/header-card.svg'
import about from '../../ui/icon/card/about.svg'
import users1 from '../../ui/icon/users1.svg'
function DomainOther() {

  const [data] = useState([
    {
      name: "Foydalanuvchilar fikri",
      icon: comment,
      route: "/comment"
    },
    {
      name: "Bosh sahifa malumotlari",
      icon: header,
      route: "/header"
    },
    {
      name: "B.S kard",
      icon: headerCard,
      route: "/header-card"
    },
    {
      name: "Kategoriya",
      icon: category,
      route: "/category"
    },
    {
      name: "Mahsulotlar",
      icon: product,
      route: "/product"
    },
    {
      name: "Sertifikat",
      icon: certificate,
      route: "/sertifkat"
    },
    {
      name: "Afzallik",
      icon: advantages,
      route: "/advantage"
    },
    {
      name: "Statistika",
      icon: statistics,
      route: "/sertifkat"
    },
    {
      name: "Biz haqimizda",
      icon: about,
      route: "/about"
    },
   
    {
      name: "Bog'lanishlar",
      icon: contact,
      route: "/contact"
    },
    {
      name: "Narxlar",
      icon: pricelist,
      route: "/price-list"
    },
  ])

  return (
    <div className='padding-unset bg-white user-profile-container bg-white home_page'>
      <div className="user-header flex between ">
        <h1 className='title'>Asosiy </h1>
      </div>
      <div className="user-body">
        <div className='grid grid-4 gap my-1'>

          {
            data?.map((item: any) => (
              <Link to={item?.route} className="user-item bg-white radius relative text-center">
                <h2 className='text-black ' >{item?.name}</h2>
                <LazyLoadImage
                  className='w100 h100 center'
                  src={item?.icon}
                  effect="blur"
                />
              </Link>
            ))
          }
        </div>


        <div className="user-info"></div>
      </div>
    </div>
  );
}

export default DomainOther;
