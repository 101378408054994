import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom"
import LeftIcon from "../../ui/icon/LeftIcon";
import axios from "axios";

import { AppDispatch } from "../../redux-toolkit/store";
import { useDispatch, useSelector } from "react-redux";
import { isFindFunction } from "../../redux-toolkit/reducer/ProfileReducer";
import TableLoader from "../../loading/TableLoader";

const Show = () => {
    const { url, id } = useParams()
    const path = useNavigate();
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch<AppDispatch>()
    const send = async () => {

        try {
            setLoading(true)
            let res = await axios.get(`/admin/${url}/${id}`)
            if (res.status === 200) {
                dispatch(isFindFunction(res.data?.data || res.data))
            }
        }
        catch (error) {
            path('/')
        }
        finally {
            setLoading(false)
        }
    }
    const { isFindData } = useSelector((state: any) => state.ProfileReducer)
    useEffect(() => {
        if (!isFindData?.id) {
            send()

        }
    }, [])
    const { categoryData, isSuccess } = useSelector((state: any) => state.CategoryReducer
    );
    return (
        <div className='contant padding-unset bg-white user-profile-container bg-white'>
            <div className="user-header flex between">
                <Link to='/' >
                    <h1 className='title flex center-align text-black'>
                        <LeftIcon />
                        Ortga
                    </h1>
                </Link>
            </div>
            {
                loading ?
                    <TableLoader /> :

                    <div className="user-body show">
                        <div>
                            <br />
                            {
                                isFindData?.poster ?
                                    <img src={isFindData?.poster} alt="rasm topilmadi" /> : ''
                            }
                            {
                                isFindData?.photo ?
                                    <img src={isFindData?.photo} alt="rasm topilmadi" /> : ''
                            }
                            {
                                isFindData?.video ?
                                    <iframe src={isFindData?.video} height={400} width={'100%'}></iframe>
                                    : ''
                            }
                            {

                                isFindData?.traslations?.uz?.title ?
                                    <>
                                        <h2><b>Nomi (uz):</b> <span>{isFindData?.traslations?.uz?.title}</span></h2>
                                    </> : ''
                            }
                            {/* {

                                isFindData?.traslations?.ru?.title ?
                                    <>
                                        <h2><b>Nomi (ru):</b> <span>{isFindData?.traslations?.ru?.title}</span></h2>
                                    </> : ''
                            } */}
                            {

                                isFindData?.traslations?.en?.title ?
                                    <>
                                        <h2><b>Nomi (en):</b> <span>{isFindData?.traslations?.en?.title}</span></h2>
                                    </> : ''
                            }
                            {
                                isFindData?.name_uz ?
                                    <>
                                        <h2><b>I.F.SH (uz):</b> <span>{isFindData?.name_uz}</span></h2>
                                    </> : ''
                            }
                            {/* {
                                isFindData?.name_ru ?
                                    <>
                                        <h2><b>I.F.SH (ru):</b> <span>{isFindData?.name_ru}</span></h2>
                                    </> : ''
                            } */}
                            {
                                isFindData?.name_en ?
                                    <>
                                        <h2><b>I.F.SH (en):</b> <span>{isFindData?.name_en}</span></h2>
                                    </> : ''
                            }

                            {
                                isFindData?.full_name ?
                                    <>
                                        <h2><b>I.F.SH:</b> <span>{isFindData?.full_name}</span></h2>
                                    </> : ''
                            }
                            {
                                isFindData?.message ?
                                    <>
                                        <p><b>Izoh:</b>{isFindData?.message}</p>

                                    </> : ''
                            }

                            {
                                url === 'partnior' && isFindData?.title ?
                                    <>
                                        <h2><b>Kampaniya nomi:</b> <span>{isFindData?.title}</span></h2>
                                    </> : ''
                            }

                            {
                                isFindData?.title_uz ?
                                    <>

                                        <h2>{isFindData?.title_uz ? <><b>Nomi(o'zbek tilida) : </b> <span>{isFindData?.title_uz}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.title_en ?
                                    <>

                                        <h2>{isFindData?.title_en ? <><b>Nomi(ingliz tilida) : </b> <span>{isFindData?.title_en}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }
                            {/* {
                                isFindData?.title_ru ?
                                    <>

                                        <h2>{isFindData?.title_ru ? <><b>Nomi(Rus tilida) : </b> <span>{isFindData?.title_ru}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            } */}

                            {
                                isFindData?.header_uz ?
                                    <>

                                        <h2>{isFindData?.header_uz ? <><b>Qisqacha malumot(o'zbek tilida) : </b> <span>{isFindData?.header_uz}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.header_en ?
                                    <>

                                        <h2>{isFindData?.header_en ? <><b>Qisqacha malumot(ingliz tilida) : </b> <span>{isFindData?.header_en}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }
                            {/* {
                                isFindData?.header_ru ?
                                    <>

                                        <h2>{isFindData?.header_ru ? <><b>Qisqacha malumot(Rus tilida) : </b> <span>{isFindData?.header_ru}</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            } */}
                            {
                                isFindData?.category ?
                                    <>
                                        <h2><b>Kategoriya: </b>  <Link className='comment_' to={`/show/category/${isFindData?.category?.id}`}
                                            onClick={() => {
                                                dispatch(isFindFunction(isFindData?.category))
                                            }}
                                        >
                                            {isFindData?.category?.title_uz}
                                        </Link></h2>


                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.price ?
                                    <>

                                        <h2>{isFindData?.price ? <><b>Maxsulot narxi : </b> <span>{isFindData?.price} so'm</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.discount ?
                                    <>
                                        <h2>{isFindData?.discount ? <><b>Maxsulot chegirmasi : </b> <span>{isFindData?.discount} %</span></> : ''}</h2>
                                        <br />
                                    </> : ''
                            }




                            {
                                isFindData?.body_uz ?
                                    <>
                                        <p>{isFindData?.body_uz ? <><b>Malumot(O'zbek tilida) : </b> <span>{isFindData?.body_uz}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.phone ?
                                    <>
                                        <p>{isFindData?.phone ? <><b>Telefon : </b> <span>{isFindData?.phone}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }
                            {/* {
                                isFindData?.body_ru ?
                                    <>
                                        <p>{isFindData?.body_ru ? <><b>Malumot(Rus tilida) : </b> <span>{isFindData?.body_ru}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            } */}
                            {
                                isFindData?.body_en ?
                                    <>
                                        <p>{isFindData?.body_en ? <><b>Malumot(ingliz tilida) : </b> <span>{isFindData?.body_en}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.level_uz ?
                                    <>
                                        <p>{isFindData?.level_uz ? <><b>Kasbi(o'zbek tilida) : </b> <span>{isFindData?.level_uz}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.description_uz ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot haqida(uz):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.description_uz}` }}></div>
                                    </div> : ''
                            }
                            {/* {
                                isFindData?.description_ru ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot haqida(ru):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.description_ru}` }}></div>
                                    </div> : ''
                            } */}
                            {
                                isFindData?.description_en ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot haqida(en):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.description_en}` }}></div>
                                    </div> : ''
                            }
                            {
                                isFindData?.instructions_uz ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot instruksiya(uz):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.instructions_uz}` }}></div>
                                    </div> : ''
                            }
                            {/* {
                                isFindData?.instructions_ru ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot instruksiya(ru):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.instructions_ru}` }}></div>
                                    </div> : ''
                            } */}
                            {
                                isFindData?.instructions_en ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot instruksiya(en):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.instructions_en}` }}></div>
                                    </div> : ''
                            }
                            {/* {
                                isFindData?.apply_uz ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot qo'llanilishi(uz):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.apply_uz}` }}></div>
                                    </div> : ''
                            }
                            {
                                isFindData?.apply_ru ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot qo'llanilishi(ru):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.apply_ru}` }}></div>
                                    </div> : ''
                            }
                            {
                                isFindData?.apply_en ?
                                    <div >
                                        <br />
                                        <br />
                                        <h2>Maxsulot qo'llanilishi(en):</h2>
                                        <div dangerouslySetInnerHTML={{ __html: `${isFindData?.apply_en}` }}></div>
                                    </div> : ''
                            } */}


                            {/* {
                                isFindData?.level_ru ?
                                    <>
                                        <p>{isFindData?.level_ru ? <><b>Kasbi(rus tilida) : </b> <span>{isFindData?.level_ru}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            } */}
                            {
                                isFindData?.level_en ?
                                    <>
                                        <p>{isFindData?.level_en ? <><b>Kasbi(ingliz tilida) : </b> <span>{isFindData?.level_en}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }
                            {
                                isFindData?.email ?
                                    <>
                                        <p>{isFindData?.email ? <><b>Email : </b> <span>{isFindData?.email}</span></> : ''}</p>
                                        <br />
                                    </> : ''
                            }

                            <br />
                            <br />
                            <p>
                                <b>Malumot qo'shilgan vaqti: </b><Moment format="hh:mm:ss DD.MM.YYYY" >
                                    {isFindData?.created_at}
                                </Moment>
                            </p>
                        </div>
                    </div>
            }




        </div>
    )
}

export default Show