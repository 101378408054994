import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { AppDispatch } from '../../redux-toolkit/store';
import DeleteIcon from '../../ui/icon/DeleteIcon';
import eyeon from '../../ui/icon/serach.svg'
import EditIcon from '../../ui/icon/EditIcon';
import LeftIcon from '../../ui/icon/LeftIcon';
import { isFindFunction, isLoadingFunction } from '../../redux-toolkit/reducer/ProfileReducer';
import TableLoader from '../../loading/TableLoader';
import { isAddAllHeaderCard, isDeleteHeaderCard } from '../../redux-toolkit/reducer/HeaderCardReducer';
function HeaderCard() {


    const path = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const handlePageClick = async (e: any) => {
        try {

            dispatch(isLoadingFunction(true))
            let res = await axios.get(`/admin/header-card?page=${e?.selected + 1}`);
            if (res.status === 200) {
                dispatch(isAddAllHeaderCard(res.data))
            }
        }
        catch (err) {
            Swal.fire({
                position: 'top-end',
                title: 'Hatolik!',
                text: "Malumot mavjud emas",
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
            })
        }
        finally {
            dispatch(isLoadingFunction(false))
        }

    }
    const [searchValue, setSearchValue] = useState('')
    const serach = (data: any) => {
        if (searchValue?.length > 0) {
            return data?.filter((item: any) => {
                let res = (item?.title_uz?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.title_ru?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.title_en?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1) || (item?.body_uz?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.body_ru?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1 || item?.body_en?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1)
                return res
            })

        }
        else {
            return data
        }
    }
    const { headerCardData, isSuccess } = useSelector((state: any) => state.HeaderCardReducer
    );
    return (
        <div className='contant padding-unset bg-white user-profile-container bg-white'>
            <div className="user-header flex between">
                <Link to='/' >
                    <h1 className='title flex center-align text-black'>
                        <LeftIcon />
                        Cardlar
                    </h1>
                </Link>
                <div className="form-input">
                    <div className="icon">
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Qidiruv...'
                            name='password'
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onKeyDown={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onKeyUp={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                        />
                        <button > <img src={eyeon} alt="search" /></button>
                    </div>
                </div >
                <div>
                    <button className='btn bg-green white' onClick={() => {
                        dispatch(isFindFunction({}))
                        path('/header-card/add')
                    }}>Qo'shish</button>
                </div>
            </div>
            {
                isSuccess ?
                    <div className="user-body">
                        <div className="resposive-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Sarlavha(uz)</th>
                                        {/* <th>Sarlavha(ru)</th> */}
                                        <th>Sarlavha(en)</th>
                                        <th>Holati</th>
                                        <th>Activaion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        serach(headerCardData?.data)?.length > 0 ?
                                            serach(headerCardData?.data)?.map((item: any, index: number) => (
                                                <tr key={item?.id}>
                                                    <td >{index + 1}</td>
                                                    <td  ><Link className='comment_' to={`/show/header-card/${item?.id}`}
                                                        onClick={() => {
                                                            dispatch(isFindFunction(item))
                                                        }}
                                                    >
                                                        {item?.title_uz}
                                                    </Link>
                                                    </td>
                                                    {/* <td  >
                                                        <Link className='comment_' to={`/show/header-card/${item?.id}`}
                                                            onClick={() => {
                                                                dispatch(isFindFunction(item))
                                                            }}
                                                        >
                                                            {item?.title_ru}
                                                        </Link>
                                                    </td> */}
                                                    <td  >
                                                        <Link className='comment_' to={`/show/header-card/${item?.id}`}
                                                            onClick={() => {
                                                                dispatch(isFindFunction(item))
                                                            }}
                                                        >
                                                            {item?.title_en}
                                                        </Link>
                                                    </td>
                                                   
                                                    <td>{item?.status===1 ? 'Faol' : 'Nofaol'}</td>
                                                    <td className='flex gap-050'>
                                                        <button className='btn bg-green btn-sm' onClick={() => {
                                                            dispatch(isFindFunction(item))
                                                            path(`/header-card/edit/${item?.id}`)
                                                        }}><EditIcon /></button>
                                                        <button className='btn bg-red btn-sm' onClick={() => {
                                                            Swal.fire({
                                                                title: "Malumotni o'chirasimi?",
                                                                showDenyButton: true,
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Ha',
                                                                denyButtonText: `Yo'q`,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    dispatch(isLoadingFunction(true))
                                                                    axios.delete(`/admin/header-card/${item?.id}`)
                                                                        .then((res) => {
                                                                            if (res.data.status === 200) {
                                                                                Swal.fire({
                                                                                    position: 'top-end',
                                                                                    icon: 'success',
                                                                                    title: "Card o'chirildi",
                                                                                    showConfirmButton: false,
                                                                                    timer: 2500
                                                                                })
                                                                                dispatch(isDeleteHeaderCard(item?.id));
                                                                                dispatch(isLoadingFunction(false))
                                                                            }
                                                                            dispatch(isLoadingFunction(false))
                                                                        })
                                                                } else if (result.isDenied) {
                                                                    Swal.fire('Changes are not saved', '', 'info')
                                                                }
                                                            })
                                                        }}><DeleteIcon /></button>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={9}><h2 className='text-center'>Malumot yo'q</h2></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            {/* <p>pagination : {ValuteData?.last_page}   current:{page}</p> */}
                            {/* <Pagination total={ValuteData?.last_page + 5} count={counts} /> */}
                        </div> <br />
                        {
                                headerCardData?.meta?.last_page > 1 ?
                                    <ReactPaginate
                                        className="pagination"
                                        breakLabel="..."
                                        nextLabel={`next >`}
                                        onPageChange={handlePageClick}
                                        prevRel={'salom'}
                                        pageCount={headerCardData?.meta?.last_page}
                                        previousLabel={`<previous `}
                                    /> : ''
                        }
                    </div> : <TableLoader />
            }

        </div>
    );
}

export default HeaderCard;
