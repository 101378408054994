import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    massage: string;
    sertifkatData: any;
}


export const isSertifkatGet = createAsyncThunk('sertifkat', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin/sertifkat');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: true,
    isLoading: false,
    hasError: false,
    massage: '',
    sertifkatData: [
       
    ]
}
export const SertifkatReducer = createSlice({
    name: 'sertifkat',
    initialState,
    reducers: {

        isDeleteSertifkat: (state, { payload }) => {
            state.sertifkatData.data = state.sertifkatData.data.filter(({ id }: { id: number }) => id !== payload)
        },
        isAddSertifkat: (state, { payload }) => {
            state.sertifkatData.data = [...state.sertifkatData.data, payload]
        },
        isAddAllSertifkat: (state, { payload }) => {
            state.sertifkatData = payload
        },
        isEditSertifkat: (state, { payload }) => {
            state.sertifkatData.data = state.sertifkatData.data.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isSertifkatGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isSertifkatGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.sertifkatData = action.payload;
            state.isSuccess = true;
        },
        [isSertifkatGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const {isEditSertifkat, isDeleteSertifkat,isAddAllSertifkat,isAddSertifkat } = SertifkatReducer.actions

export default SertifkatReducer.reducer