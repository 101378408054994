import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export interface TypeState {
    isSuccess: boolean;
    isLoading: boolean;
    hasError: boolean;
    partnerData: any;
    massage: string;
}


export const isPartnerGet = createAsyncThunk('partner', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get('/admin-api/worker');
        return data
    }
    catch (err: any) {
        rejectWithValue(err.response.data)
        return err
    }
})
const initialState: TypeState = {
    isSuccess: false,
    isLoading: false,
    hasError: false,
    massage: '',
    partnerData: []
}
export const PartnerReducer = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        isAddPartner: (state, { payload }) => {
            state.partnerData = [...state.partnerData, payload]
        },
        isAddAllPartner: (state, { payload }) => {
            state.partnerData = payload
        },
        isDeletePartner: (state, { payload }) => {
            state.partnerData = state.partnerData.filter(({ id }: { id: number }) => id !== payload)
        },
        isEditPartner: (state, { payload }) => {
            state.partnerData = state.partnerData.map((item: any) => item.id === +payload.id ? payload : item)
        },
    },
    extraReducers: {
        [isPartnerGet.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [isPartnerGet.fulfilled.toString()]: (state: any, action: any) => {
            state.isLoading = true;
            state.partnerData = action.payload;
            state.isSuccess = true;
        },
        [isPartnerGet.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            state.massage = action;
        }
    }
})
export const { isAddPartner, isDeletePartner, isEditPartner, isAddAllPartner } = PartnerReducer.actions

export default PartnerReducer.reducer