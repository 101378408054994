import HomeComponets from '../components/home/HomeComponets';
import Contet from './layout/Contet';

function Home() {
  return (
    <div >
      <Contet Component={HomeComponets} />
    </div>
  );
}

export default Home;
