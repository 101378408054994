import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export interface TypeState {
    menu: boolean;
    rules: boolean;
    refresh: string;
    token: string;
    profile: object;
    saveCrud: object;
    url: string;
    isSuccess: boolean;
    isLoading: boolean;
    crudRules: boolean;
    isFindData: object;
}
const initialState: TypeState = {
    menu: false,
    rules: true,
    saveCrud: {},
    token: localStorage.getItem('token') || '',
    refresh: localStorage.getItem('refresh') || '',
    url: 'http://127.0.0.1:8000/',
    profile: {},
    isSuccess: false,
    isLoading: false,
    crudRules: false,
    isFindData: { id: null }
}
export const userget = createAsyncThunk('profile', async (arg, {
    rejectWithValue
}) => {
    const { data } = await axios.get('/api/user');
    return data
})
export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        isSaveCrudFunction: (state, { payload }) => {
            state.saveCrud = payload;
        },
        menuBtn: (state,{payload}) => {
            state.menu = payload;
        },
        profileRules: (state, { payload }) => {
            state.rules = true;
            state.profile = payload
            localStorage.setItem('token', payload?.token);
            state.token = payload?.token
        },
        userData: (state, { payload }) => {
            state.token = payload.token;
            localStorage.setItem('token', payload.access);
            state.profile = payload.user;
            state.rules = true;
        },
        userGet: (state, { payload }) => {
            state.rules = true;
            state.profile = payload;
        },
        isLogoutFunction: (state) => {
            state.token = '';
            window.localStorage.clear()
            localStorage.removeItem('token');
            state.rules = false;
        },
        loadFun: (state) => {
            state.isLoading = true;
            state.isSuccess = true;
        },
        isLoadingFunction: (state, { payload }) => {
            state.isLoading = payload;
        },
        loadStart: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        addRules: (state, { payload }) => {
            state.crudRules = payload
        },
        isFindFunction: (state, { payload }) => {
            state.isFindData = payload
        }
    },
    extraReducers: {
        [userget.pending.toString()]: (state: any, action: any) => {
            state.isLoading = true;
        },
        [userget.fulfilled.toString()]: (state: any, action: any) => {
            state.profile = action.payload;
            state.rules = true;
            state.isSuccess = true;
            state.isLoading = true;
        },
        [userget.rejected.toString()]: (state: any, action: any) => {
            state.isLoading = false;
            if (action.error?.message.indexOf('401')) {
                state.token = localStorage.removeItem('token');
                state.rules = false;
                state.isLoading = true;
                state.isSuccess = true;
            }
            state.massage = action;
        }

    }
})
export const {userGet,isLogoutFunction, isLoadingFunction, menuBtn, profileRules, loadFun, loadStart, addRules, isFindFunction } = ProfileSlice.actions

export default ProfileSlice.reducer